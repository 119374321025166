import { PlacementsSection } from 'components/bricks/types/placement.type';
import Translation from 'components/data/Translation';

const placements: PlacementsSection[] = [
    {
        key: 'feed',
        label: Translation.get('adSetup.placements.feed', 'bricks'),
        children: [
            {
                recommendedHeight: 1,
                minHeight: 600,
                recommendedWidth: 1.91,
                minWidth: 600,
                type: 'feed',
                key: 'youtube-post',
                label: 'Youtube Post',
                platform: 'youtube',
                channel: 'youtube',
                frameWidth: 340,
                multiFrame: false
            }
        ]
    }
];

export default placements;
