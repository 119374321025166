import React from 'react';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import TabWrapper from 'components/bricks/components/shared/components/tab-wrapper';
import BricksCreativeInput from 'components/bricks/components/shared/components/creative-input/components';
import Accordion from 'components/bricks/components/shared/components/accordion';
import Translation from 'components/data/Translation';
import useBrick from '../../../../../hooks/useBrick';

import '../styles/creative.scss';

const Creative: React.FC = () => {
    const { brick } = useBrick();

    if (!brick) return null;

    return (
        <TabWrapper>
            <Accordion title={Translation.get('creative', 'bricks')}>
                <div>
                    <div className="youtube-post-creative-actions">
                        <IconButton className="youtube-post-creative-actions__icon">
                            <Icon>edit</Icon>
                        </IconButton>
                        <IconButton className="youtube-post-creative-actions__icon">
                            <Icon>delete</Icon>
                        </IconButton>
                    </div>
                    <BricksCreativeInput assetType="video" brick={brick} />
                </div>
            </Accordion>
        </TabWrapper>
    );
};

export default Creative;
