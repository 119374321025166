import React from 'react';

import '../styles/tabbar.scss';

interface Props {
    children?: React.ReactNode;
}

const OutputDialogTabBar: React.FC<Props> = ({ children }) => {
    return <div className={'output-dialog-tabbar'}>{children}</div>;
};

export default OutputDialogTabBar;
