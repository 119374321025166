import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import { ValidationObject } from 'components/bricks/types/validator.type';
import { AD_SETUP } from 'components/bricks/constants';

const validators: ValidationObject = {
    validators: [
        {
            condition: `!brick.data?.adSetup`,
            message: 'There is no data in ad setup',
            inputIdentifiers: ['youtube_post-ad_setup'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${AD_SETUP}.items?.[0] && !${AD_SETUP}.items[0].creativeIds?.length`,
            message: 'There are no creatives',
            inputIdentifiers: ['youtube_post-ad_setup-creative_input'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'youtube-post'),
            message: 'Youtube post has not assigned creative',
            inputIdentifiers: ['youtube_post-ad_setup-placements-youtube-post'],
            severity: 'warning',
            tabKey: 'adSetup'
        },
        {
            condition: `!${AD_SETUP}.items?.[0]?.snippet?.title?.length || ${AD_SETUP}.items?.[0]?.snippet?.title?.length < 1`,
            message: 'Title is required',
            inputIdentifiers: ['youtube_post-ad_setup-title'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `!${AD_SETUP}.items?.[0]?.snippet?.description?.length || ${AD_SETUP}.items?.[0]?.snippet?.description?.length < 1`,
            message: 'Description is required',
            inputIdentifiers: ['youtube_post-ad_setup-description'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: `${AD_SETUP}.items?.[0]?.snippet?.description?.match(/\\b\\w{51,}\\b/)`,
            message: "Description shouldn't contain words longer than 50 characters",
            inputIdentifiers: ['youtube_post-ad_setup-description'],
            severity: 'warning',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w{51,}\b/ }],
                    severity: 'warning',
                    message: "Description shouldn't contain words longer than 50 characters"
                }
            ]
        },
        {
            condition: `${AD_SETUP}.items?.[0]?.snippet?.description?.match(/[<>]/g)`,
            message: 'Angled brackets are not allowed',
            inputIdentifiers: ['youtube_post-ad_setup-description'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[<>]/g }],
                    severity: 'error',
                    message: 'Angled brackets are not allowed'
                }
            ]
        },
        {
            condition: `${AD_SETUP}.items?.[0] && ${AD_SETUP}.items[0].snippet.description.length > 5000`,
            message: 'Description length must be below 5000 characters',
            inputIdentifiers: ['youtube_post-ad_setup-description'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    severity: 'error',
                    max: 5000,
                    message: 'Description length must be below 5000'
                }
            ]
        },
        {
            condition: `${AD_SETUP}.items?.[0] && ${AD_SETUP}.items[0].snippet.description.length < 10`,
            message: 'Description should be at least 10 characters long',
            inputIdentifiers: ['youtube_post-ad_setup-description'],
            severity: 'warning',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    severity: 'warning',
                    min: 10,
                    message: 'Description should be at least 10 characters long'
                }
            ]
        }
    ]
};

export default validators;
