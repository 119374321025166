import React, { useEffect, useState } from 'react';
import { Brick } from 'components/bricks/types/brick.type';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import GridWrapper from 'components/creatives-v2/components/grid-wrapper';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { IframeData, IframeOverwrites } from 'components/creatives-v2/creative-types/template-creative.class';
import { getCreativeInstance } from 'components/creatives-v2/helpers/creatives-factory';

interface Props {
    brick: Brick;
    activeFormat?: string;
    handleFormats: (formats: string[]) => void;
}

const OutputDialogCreativePreview: React.FC<Props> = ({ brick, activeFormat, handleFormats }) => {
    const [enrichedCreatives, setEnrichedCreatives] = useState<CreativeV2Enriched[]>([]);
    const [iframeOverwrites, setIframeOverwrites] = useState<IframeOverwrites<IframeData>>({});

    const handleCreativesFormats = async () => {
        if (!brick) return;

        const creatives = await CreativeV2Helpers.getCreativesForBrick(brick);
        const activeFormats: string[] = creatives[0]?.data?.settings?.activeFormats ?? [];
        const iframeOverwrites = getIframeOverwrites(creatives);

        setEnrichedCreatives(creatives);
        setIframeOverwrites(iframeOverwrites);
        handleFormats(activeFormats);
    };

    useEffect(() => {
        handleCreativesFormats();
    }, [brick]);

    const getIframeOverwrites = (creatives: CreativeV2Enriched[]): IframeOverwrites<IframeData> => {
        const showPlaybar = getCreativeInstance(creatives[0])?.showPlayBar();

        return {
            data: {
                autoplay: showPlaybar,
                showPlaybar
            }
        };
    };

    return (
        <GridWrapper
            creatives={enrichedCreatives}
            singleViewKey={activeFormat}
            disableZoom
            iframeOverwrites={iframeOverwrites}
            classes={{ creativeItem: 'output-jobs-dialog-preview__content__item' }}
            view={'single'}
            hideOverlay
        />
    );
};

export default OutputDialogCreativePreview;
