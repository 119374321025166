import { RowSelectionState } from '@tanstack/react-table';
import { filterOutBricksWithValidationErrors } from 'components/bricks/helpers/brick-filters.helpers';
import { Brick } from 'components/bricks/types/brick.type';

class OutputTreeViewHelper {
    /** This function returns only bricks that doesn't have any validation errors. */
    static getDefaultCheckedBricks(bricks: Brick[]): RowSelectionState {
        {
            const bricksWithoutErrors = filterOutBricksWithValidationErrors(bricks);

            return bricksWithoutErrors.reduce((acc, brick) => {
                acc[brick.id] = true;
                return acc;
            }, {} as RowSelectionState);
        }
    }
}

export default OutputTreeViewHelper;
