import { ColumnDef } from '@tanstack/react-table';
import { BrickTree } from 'components/bricks/types/brick.type';

/**
 * This file contains the columns setup for the publish dialog tree view.
 */
const outputDialogColumnsSetup: ColumnDef<BrickTree>[] = [
    {
        accessorFn: (row) => row,
        id: 'row'
    }
];

export default outputDialogColumnsSetup;
