import briefing from 'components/bricks/components/shared/components/briefing-content/components';
import output from 'components/bricks/components/shared/components/output-tab';
import adSetup from 'components/bricks/components/shared/components/ad-setup';
import settings from './settings';
import setup from './setup';
import icon from './icon.svg';
import defaultData from './default-data';
import creative from './creative';
import adSetupSingleFrame from './ad-setup-single-frame';
import placements from '../data/placements';
import validators from './validators';

export default { settings, setup, icon, defaultData, creative, adSetupSingleFrame, adSetup, placements, validators, briefing, output };
