const defaultData = {
    data: {
        adSetup: {
            type: 'single',
            items: [
                {
                    snippet: {
                        title: '',
                        description: '',
                        category: '22',
                        keywords: []
                    },
                    status: {
                        privacyStatus: 'public'
                    }
                }
            ]
        }
    }
};

export default defaultData;
