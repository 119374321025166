import React, { useMemo } from 'react';
import SettingsPreview from 'components/bricks/components/shared/components/settings-preview';
import SettingsPreviewData from 'components/bricks/components/shared/components/settings-preview/interfaces/settings-preview-data';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { Brick } from 'components/bricks/types/brick.type';
import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

interface Props {
    brick: Brick;
}

const OutputDialogSettingsPreview: React.FC<Props> = ({ brick }) => {
    const getSettingsPreviewData = (): SettingsPreviewData | undefined => {
        if (!brick) return;

        const keyAndValueObject: SettingsPreviewData = { title: brick.title };

        const setup: IEditorBlock[] =
            brick.subType === 'meta_adset' ? BrickHelpers.getBrickData(brick.subType, 'settingsInputs') : BrickHelpers.getBrickData(brick.subType, 'settings');
        if (!Array.isArray(setup) || setup.length === 0) return;

        const settingsObject: SettingsPreviewData | undefined = brick?.data?.settings;

        if (!settingsObject) return keyAndValueObject;

        const keys = Object.keys(settingsObject);
        keys.forEach((key) => {
            const value = settingsObject[key];

            if (typeof value === 'string') {
                keyAndValueObject[key] = value;
            }
        });

        return keyAndValueObject;
    };

    const settingsPreviewData = useMemo(() => getSettingsPreviewData(), [brick]);

    return <SettingsPreview data={settingsPreviewData} />;
};

export default OutputDialogSettingsPreview;
