import { DotIndicatorSeverity } from 'components/bricks/components/shared/components/dot-indicator';
import { BrickPublishJob } from 'components/bricks/types/brick-publish.type';
import { JobStatus } from 'components/bricks/types/publish.type';
import { ValidationResult } from 'components/bricks/types/validator.type';
import Translation from 'components/data/Translation';

/**
 * This helper class is responsible for providing the necessary functions to the publish tree view item component.
 * This class separates the logic from the component to make more readable.
 */
class OutputTreeViewItemHelper {
    /** Checks if the most recent brick changes are published. */
    static isRecentChangePublished = (recentPublishJob?: BrickPublishJob, brickUpdatedAt?: Date): boolean => {
        const recentPublishCreatedAt = recentPublishJob?.createdAt;
        const timeDifferenceThreshold = 45; // If the time difference is less or equal to 30 seconds then the changes are considered up to date.

        if (!recentPublishCreatedAt || !brickUpdatedAt) return true; // If the dates are not found then it's assumed all the changes are up to date.

        const recentPublishDate = new Date(recentPublishCreatedAt);
        const lastBrickModificationDate = new Date(brickUpdatedAt);

        const timeDifferenceInSeconds = Math.abs((recentPublishDate.getTime() - lastBrickModificationDate.getTime()) / 1000);

        return timeDifferenceInSeconds <= timeDifferenceThreshold;
    };

    /** Get validation success for the publish dialog. */
    static getValidationSuccess = (recentPublishJob?: BrickPublishJob): ValidationResult[] => {
        const success: ValidationResult[] = [];

        if (recentPublishJob?.status === JobStatus.FINISHED) {
            return [...success, { severity: 'success', message: Translation.get('output.publish.validations.itemUpToDateWithLastPublish', 'bricks') }];
        }
        return success;
    };

    /** Get validation errors for the publish dialog. */
    static getValidationErrors = (recentPublishJob?: BrickPublishJob, validationErrors?: ValidationResult[]): ValidationResult[] => {
        const errors = validationErrors ? [...validationErrors] : [];

        if (recentPublishJob?.status === JobStatus.FAILED) {
            return [...errors, { severity: 'error', message: Translation.get('output.publish.validations.failedToPublish', 'bricks') }];
        }
        return errors;
    };

    /** Get validation warnings for the publish dialog. */
    static getValidationWarnings = (recentPublishJob?: BrickPublishJob, validationWarnings?: ValidationResult[], brickUpdatedAt?: Date): ValidationResult[] => {
        const warnings = validationWarnings ? [...validationWarnings] : [];

        // Check if the latest changes are published
        if (!this.isRecentChangePublished(recentPublishJob, brickUpdatedAt)) {
            return [...warnings, { severity: 'warning', message: Translation.get('output.publish.validations.itemEditedAfterLastPublish', 'bricks') }];
        }

        return warnings;
    };

    /** Get the severity of the publish tree view item */
    static getPublishSeverity = (recentPublishJob?: BrickPublishJob, errors?: ValidationResult[], warnings?: ValidationResult[]): DotIndicatorSeverity => {
        if (errors && errors.length > 0) return 'error';
        if (warnings && warnings.length > 0) return 'warning';
        if (recentPublishJob?.status === JobStatus.FINISHED) return 'success';
        return 'default';
    };
}

export default OutputTreeViewItemHelper;
