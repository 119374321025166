import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_PUBLISH_METADATA } from 'components/bricks/constants';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                identifier: 'youtube_ad-title',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        blockModel: MODEL_PUBLISH_METADATA + '.videoSettings.snippet',
        opened: true,
        items: [
            {
                type: 'text',
                multiline: true,
                useValueObject: false,
                model: 'description',
                identifier: 'youtube_post-description',
                label: 'Description',
                placeholder: 'Add optional description to this creative set'
            }
        ]
    }
];

export default settings;
