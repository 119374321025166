import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import CreativePreviewHelper from 'components/bricks/components/shared/components/creatives-preview/helpers/creatives-preview.helpers';
import { Brick } from 'components/bricks/types/brick.type';
import SocialPreview from 'components/creatives-v2/components/social-previews-v2';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';

interface Props {
    brick: Brick;
    activeFormat?: string;
    handleFormats: (formats: string[]) => void;
}

const OutputDialogSocialPreview: React.FC<Props> = ({ brick, handleFormats, activeFormat }) => {
    const [enrichedCreatives, setEnrichedCreatives] = useState<CreativeV2Enriched[]>([]);

    const handleCreatives = async () => {
        if (!brick) return;

        const creatives = await CreativeV2Helpers.getCreativesForBrick(brick);
        setEnrichedCreatives(creatives);
    };

    const getSocialPreviewData = () => {
        if (!brick || !activeFormat || !enrichedCreatives) return;

        const activePlacementObject = CreativePreviewHelper.getPlacementObject(brick.subType, activeFormat);
        return CreativePreviewHelper.generateSocialPreviewData(brick, enrichedCreatives, activePlacementObject);
    };

    const socialPreviewData = useMemo(() => getSocialPreviewData(), [brick, activeFormat, enrichedCreatives]);

    const handleAvailablePlacements = () => {
        if (!brick) return;

        const availablePreviewPlacements = CreativePreviewHelper.getAvailablePreviewPlacements(brick, brick?.data?.adSetup?.type);
        const formats = availablePreviewPlacements.map((placement) => placement.value);
        handleFormats(formats);
    };

    useEffect(() => {
        handleCreatives();
        handleAvailablePlacements();
    }, [brick]);

    if (!socialPreviewData) return;

    return <SocialPreview socialPreview={socialPreviewData} />;
};

export default OutputDialogSocialPreview;
